import React from "react"
import Marquee from "react-fast-marquee"

export const Ticker = ({ tickerDuration, tickerData }) => {
  if (!tickerData || !tickerDuration) return null

  const { duration = 90 } = tickerDuration

  const marqueeSettings = {
    pauseOnHover: true,
    speed: duration / 2,
    direction: "left",
  }

  return (
    <div className="ticker-wrapper">
      <div className="ticker-container">
        <Marquee
          {...marqueeSettings}
          className="ticker ticker-pr-100"
          //style={{ animationDuration: `${duration}s` }}
        >
          {tickerData.map((item, index) => {
            if (!item?.text?.text) return null
            const {
              text: { text },
            } = item
            return (
              <li key={index} data-update="item1">
                {text}
              </li>
            )
          })}
        </Marquee>
      </div>
    </div>
  )
}
