import React from "react"
import { Link } from "gatsby"

import { ModalWrapper } from "./ModalWrapper"

export const MenuModal = ({ onClose, navModal }) => {
  const closeHandler = () => {
    setTimeout(() => onClose())
  }

  return (
    <ModalWrapper onClose={() => onClose()} modalType="menu">
      <div className="modal-menu-wrapper">
        <ul>
          {navModal.map(({ title, link, disabled, external }, index) => {
            if (disabled) {
              return (
                <li key={index} className="coming-soon">
                  <a href>
                    {title} <span>COMING SOON</span>
                  </a>
                </li>
              )
            }

            if (external) {
              return (
                <li key={index}>
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => closeHandler()}
                  >
                    {title}
                  </a>
                </li>
              )
            }

            return (
              <li key={index}>
                <Link to={link} onClick={() => closeHandler()}>
                  {title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </ModalWrapper>
  )
}
