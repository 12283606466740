import React from "react"
import { Link } from "gatsby"

const MyLink = ({ to, target, noFollow, children, className, onClick, ariaLabel, ...props }) => {
  if (
    /^(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?$/.test(to) ||
    // eslint-disable-next-line no-useless-escape
    /^mailto:([^?]*)$/.test(to) ||
    target !== null ||
    noFollow === true
  )
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={to || "/"}
        target="_blank"
        rel={`noopener noreferrer ${noFollow ? "nofollow" : ""}`}
        className={className}
        aria-label={ariaLabel}
        {...props}
      >
        {children}
      </a>
    )
  return (
    <Link to={to || "/"} className={className} aria-label={ariaLabel}>
      {children}
    </Link>
  )
}

export const AnnouncementBar = ({ visible, text, link }) => {
  if (!visible) return null
  return (
    <MyLink to={link} className="announcement-bar">
      {text}
      <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5H16M16 5L12 1M16 5L12 9" stroke="white" />
      </svg>
    </MyLink>
  )
}
