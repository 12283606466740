import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import PageTransition from "gatsby-plugin-page-transitions"

import { SEO } from "./SEO"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { Cta } from "./Cta"
import { Ticker } from "./Ticker"
import { MenuModal } from "./MenuModal"
import { MenuModalMobile } from "./MenuModalMobile"
import { ContactUsModal } from "./ContactUsModal"
import childrenContext from "./childrenContext"
import { AnnouncementBar } from "./AnnouncementBar"
import { StickyContainer, Sticky } from "react-sticky"

export const Layout = ({
  barData,
  headerData,
  footerData,
  tickerDuration,
  tickerData,
  ctaTitle,
  ctaType = "",
  ctaLink = false,
  ctaDeadline = "",
  ctaDisplay = true,
  isHomePage = false,
  pageTitle = null,
  children,
  title = "",
  globalMetaData = null,
  metaData = null,
  setModalForm,
  isFutureFounderBtn = false,
  className = "",
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false)
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const boolParam = param => window.location.hash === "#" + param

      if (boolParam("join")) setIsOpenMenu(true)
      if (boolParam("menu")) setIsOpenMenuMobile(true)
      if (boolParam("contact")) setIsOpenContactModal(true)
    }
  }, [])

  const changeUrlCLose = () => {
    if (typeof window !== "undefined") {
      navigate(window.location.pathname)
    }
  }

  const Modals = () => {
    if (isOpenMenu) {
      navigate(`#join`)
      return (
        <MenuModal
          onClose={() => {
            changeUrlCLose()
            setIsOpenMenu(false)
          }}
          navModal={navModal}
        />
      )
    }
    if (isOpenMenuMobile) {
      navigate(`#menu`)
      return (
        <MenuModalMobile
          onClose={() => {
            changeUrlCLose()
            setIsOpenMenuMobile(false)
          }}
          navModalMobile={navModalMobile}
        />
      )
    }
    if (isOpenContactModal) navigate(`#contact`)
    return null
  }

  const navHeader = [
    {
      title: "Team",
      link: "/team",
    },
    {
      title: "Companies",
      link: "/companies",
    },
    {
      title: "News",
      link: "/news",
    },
    {
      title: "Blog",
      link: "/blog",
    },
    {
      title: "Video",
      link: "/video",
    },
  ]

  const navSiteMap = [
    [
      {
        title: "Home",
        link: "/",
      },
      {
        title: "Team",
        link: "/team",
      },
      {
        title: "Companies",
        link: "/companies",
      },
      {
        title: "Press",
        link: "/news",
      },
    ],
    [
      {
        title: "Blog",
        link: "/blog",
      },
      {
        title: "Co-Found with us",
        link: "/co-found",
      },
      {
        title: "Join a team",
        link: "/join-team",
      },
      // {
      //   title: "Summer Internship",
      //   link:
      //     "https://jobs.lever.co/atomic/11ad695b-931e-4c7d-8fd2-8b5a2c89a864",
      //   external: true
      // }
    ],
  ]

  const navFooter = [
    {
      title: "PRIVACY POLICY",
      link: "/privacy-policy",
    },
  ]

  const navModal = [
    {
      title: "Co-found with Atomic",
      link: "/co-found",
    },
    {
      title: "Join a Team",
      link: "/join-team",
    },
    // {
    //   title: "Summer Internship",
    //   link: "https://jobs.lever.co/atomic/11ad695b-931e-4c7d-8fd2-8b5a2c89a864",
    //   external: true
    // }
  ]

  const navModalMobile = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Team",
      link: "/team",
    },
    {
      title: "Companies",
      link: "/companies",
    },
    {
      title: "News",
      link: "/news",
    },
    {
      title: "Blog",
      link: "/blog",
    },
    {
      title: "Video",
      link: "/video",
    },
    {
      title: "Co-found with Atomic",
      link: "/co-found",
    },
    {
      title: "Join a Team",
      link: "/join-team",
    },
  ]

  const [titleTemplate, description, keywords, image] = [
    metaData?.title,
    metaData?.desc?.desc || globalMetaData?.desc?.desc,
    metaData?.keywords?.keywords || globalMetaData?.keywords?.keywords,
    metaData?.image?.file.src || globalMetaData?.image?.file.src,
  ]

  return (
    <PageTransition transitionTime={250}>
      <StickyContainer
        className={`page-wrapper${
          barData?.visible ? " page-wrapper--bar" : ""
        }${isHomePage ? " is-home" : ""} ${className}`}
      >
        <SEO
          title={title}
          titleTemplate={titleTemplate}
          description={description}
          keywords={keywords}
          image={"https:" + image}
        />
        <Sticky topOffset={1}>
          {({ style, isSticky }) => (
            <div className={`page-wrapper__header ${isSticky ? "is-sticky" : ""}`}>
              <AnnouncementBar {...barData} />

              <Header
                barData={barData}
                headerData={headerData}
                navHeader={navHeader}
                openMenu={() => setIsOpenMenu(true)}
                openMenuMobile={() => setIsOpenMenuMobile(true)}
                pageTitle={pageTitle}
                className={`${isSticky ? "is-sticky" : ""}`}
                style={style}
              />
            </div>
          )}
        </Sticky>
        <childrenContext.Provider
          value={{
            openContactModal: () => setIsOpenContactModal(true),
            openMenu: () => setIsOpenMenu(true),
          }}
        >
          {children}
        </childrenContext.Provider>

        {ctaDisplay && (
          <Cta
            title={ctaTitle}
            ctaType={ctaType}
            ctaLink={ctaLink}
            ctaDeadline={ctaDeadline}
            openMenu={() => setIsOpenMenu(true)}
            openMenuMobile={() => setIsOpenMenuMobile(true)}
            openContactModal={() => setIsOpenContactModal(true)}
            setModalForm={() => setModalForm()}
            isFutureFounderBtn={isFutureFounderBtn}
          />
        )}
        <Ticker tickerData={tickerData} tickerDuration={tickerDuration} />
        <Footer
          footerData={footerData}
          navSiteMap={navSiteMap}
          navFooter={navFooter}
        />
        <Modals />
        <ContactUsModal
          onClose={() => {
            changeUrlCLose()
            setIsOpenContactModal(false)
          }}
          isOpenContactModal={isOpenContactModal}
        />
      </StickyContainer>
    </PageTransition>
  )
}
