import React from "react"
import { Link } from "gatsby"

export const Cta = ({
  ctaType,
  title,
  openMenu,
  openMenuMobile,
  openContactModal,
  ctaLink,
  ctaDeadline,
  setModalForm,
  isFutureFounderBtn,
}) => (
  <div className={`cta ${ctaType} ${ctaLink ? "cta--link" : ""}`}>
    <div className="container">
      {!!title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
      <div className="cta-btns">
        {ctaLink ? (
          <div style={{ width: "100%" }}>
            {ctaLink.show && !isFutureFounderBtn ? (
              ctaLink.url.includes("https://atomic.vc/") ? (
                <Link
                  to={ctaLink.url.replace("https://atomic.vc", "")}
                  className="btn btn-large btn-red"
                >
                  {ctaLink.title}
                </Link>
              ) : (
                <a
                  href={ctaLink.url}
                  className="btn btn-large btn-red"
                  target="_blank"
                  rel="noreferrer"
                >
                  {ctaLink.title}
                </a>
              )
            ) : (
              ""
            )}
            {ctaLink.show && isFutureFounderBtn ? (
              <button
                style={{ cursor: "pointer", outline: "none" }}
                onClick={() => setModalForm()}
                className="btn btn-large btn-red"
              >
                {ctaLink.title}
              </button>
            ) : (
              ""
            )}

            {ctaDeadline && (
              <p
                className="deadline-notice futurefounders"
                dangerouslySetInnerHTML={{ __html: ctaDeadline }}
              />
            )}
          </div>
        ) : (
          <>
            <button className="btn btn-menu--desktop" onClick={openMenu}>
              Learn more
            </button>
            <button className="btn btn-menu--mobile" onClick={openMenuMobile}>
              Learn more
            </button>
            <button className="btn btn-red" onClick={openContactModal}>
              Get in touch
            </button>
          </>
        )}
      </div>
    </div>
  </div>
)
